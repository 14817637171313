import GeoSuggest from "@ubilabs/react-geosuggest";
import { useEffect, useState } from "react";
import geosuggest from "../../containers/profile/address/sub/geosuggest.module.scss";
import * as sqtype from "../../api/store/storeQueryTypes";

interface DataType {
  location: sqtype.Location;
  label: string;
}

interface PropsTypes {
  onLocationChange: (data: DataType) => void;
  disableAutoLoad?: boolean;
  initialValue?: string;
}

const options = {
  enableHighAccuracy: true,
  timeout: 10000,
};

const AddressInput = (props: PropsTypes) => {
  const [state, setState] = useState<DataType>({
    location: {
      lat: 0,
      lng: 0,
    },
    label: "",
  });

  useEffect(() => {
    if (props.disableAutoLoad) {
      return;
    }

    const lastLocation = localStorage.getItem("lastLocation");
    if (lastLocation) {
      const location = JSON.parse(lastLocation);
      setState(location);

      props.onLocationChange(location);
    }
  }, []);


  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      options
    );
  };



  const successCallback = (position: GeolocationPosition) => {

    const geocoder = new google.maps.Geocoder();
    const location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    geocoder?.geocode({ location: location }, (results: any, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const address = results[0]?.formatted_address;
        const data = { location, label: address || "" };
        setState(data);
        props.onLocationChange(data);
        localStorage.setItem("lastLocation", JSON.stringify(data));
      }
    });
  };

  const errorCallback = (ev: GeolocationPositionError) => {
    console.log("error", ev);

  };

  return (
    <div className="flex gap-[20px] py-[12px] px-[20px] rounded-[4px] self-stretch items-center border-white border-solid border-[1px] sup-input ">
      <GeoSuggest
        country={["ZA", "BW", "LS"]}
        fixtures={[]}
        maxFixtures={5}
        minLength={2}
        initialValue={props.initialValue || state.label}
        types={["address"]}
        className={`w-full ${geosuggest.geosuggest}`}
        inputClassName={`w-full bg-transparent text-[16px] font-font-family-body font-normal placeholder:text-inherit `}
        placeholder="Enter your street address:"
        onSuggestSelect={(opt) => {
          console.log(opt);

          const label = opt?.gmaps?.formatted_address || "";
          const location = {
            lat: opt?.location?.lat || 0,
            lng: opt?.location?.lng || 0,
          };

          const data = { location, label };

          props.onLocationChange(data);

          localStorage.setItem("lastLocation", JSON.stringify(data));
        }}
        suggestsClassName={"w-full"}
      />
      <button
        className="w-[22.5px] h-[22.5px] shrink-0 relative self-start inline-flex"
        onClick={getLocation}
      >
        <svg>
          <use href="/sprites/sprites.svg#locationIcon" />
        </svg>
      </button>
    </div>
  );
};

export default AddressInput;
