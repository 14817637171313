import LoadingSkeleton from "./sub/LoadingSkeleton";
import useStoreLocator, { Handle, Options } from "./sub/useStoreLocator";
import AddressBlob from "../../../ui-update/address";
import { useEffect } from "react";
import { Location } from "../../../api/store/storeQueryTypes";

interface PropsType {
  location?: Location;
}

const HomeStoreListings = (props: PropsType) => {
  const handle = useStoreLocator({
    channelType: "WEBSITE",
    location: props.location,
  });

  useEffect(() => {
    if (!props.location) {
      return;
    }

    handle.loadWithLocation(props.location);
  }, [props.location]);

  return (
    <>
      {handle.state.mode === "initial-loading" && <LoadingSkeleton />}

      {handle.state.mode === "loaded" && (
        <>
          <div className="flex flex-col gap-[12px] items-start self-stretch md:flex-row md:flex-wrap">
            {handle.state.data?.stores.map((store) => (
              <>
                <div className="w-full md:w-[358px] ">
                  <AddressBlob
                    key={store.id}
                    store={store}
                    onServiceTypeSelect={(serviceType) => {
                      handle.selectStore(store, serviceType);
                    }}
                  />
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default HomeStoreListings;
