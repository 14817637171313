

const LoadingSkeleton = () => {
  return (
    <div className="space-y-5 rounded-2xl bg-white/5 p-4 animate-pulse">

      <div className="flex gap-1 justify-center">
        <div className="flex flex-col gap-2">
          <div className="h-8 w-40 bg-gray-600/20"></div>
          <div className="flex items-center gap-2">
            <div className="h-2 w-10 bg-gray-600/20"></div>
            <div className="h-2 w-10 bg-gray-600/10"></div>
            <div className="h-2 w-10 bg-gray-600/10"></div>

          </div>

        </div>
      </div>


      <div className="flex flex-col gap-2">
        {Array.from(Array(10)).map((v, idx) => (<>
          <div className="max-w-2xl mx-auto w-full p-1">
            <div className="h-20 flex justify-between rounded-lg bg-gray-600/10 p-4">
              <div className="flex gap-1">
                <div className="h-10 w-10 rounded-lg bg-gray-600/10 p-4">
                </div>
                <div className="flex flex-col gap-2">
                  <div className="h-5 w-20 bg-gray-600/10"></div>
                  <div className="h-2 w-20 bg-gray-600/10"></div>
                </div>
              </div>
              <div>
                <div className="h-2 w-20 rounded-lg bg-gray-600/10 p-2"></div>
              </div>
              <div></div>
            </div>
          </div>
        </>))}
      </div>
    </div>
  );
};

export default LoadingSkeleton;